/** @jsx jsx */
import { jsx, Link as TLink, Heading } from 'theme-ui';
import { Box, Flex } from '@theme-ui/components';
import kebabCase from 'lodash.kebabcase';
import { Link } from 'gatsby';
import Layout from './layout';
import useMinimalBlogConfig from '../hooks/use-minimal-blog-config';
import SEO from './seo';
import replaceSlashes from '../utils/replaceSlashes';

type PostsProps = {
  list: {
    fieldValue: string;
    totalCount: number;
  }[];
};

const Tags = ({ list }: PostsProps) => {
  const { tagsPath, basePath } = useMinimalBlogConfig();

  return (
    <Layout>
      <SEO title="Tags" pathname={tagsPath} />
      <Heading variant="styles.h2">Tags</Heading>
      <Box mt={[4, 5]}>
        {list.map((listItem) => (
          <Flex key={listItem.fieldValue} mb={[1, 1, 2]} sx={{ alignItems: `center` }}>
            <TLink
              as={Link}
              sx={{ color: 'text', variant: `links.simple`, fontSize: [1, 2, 3], mr: 2 }}
              to={replaceSlashes(`/${basePath}/${tagsPath}/${kebabCase(listItem.fieldValue)}`)}
            >
              {listItem.fieldValue} <span sx={{ color: `secondary` }}>({listItem.totalCount})</span>
            </TLink>
          </Flex>
        ))}
      </Box>
    </Layout>
  );
};

export default Tags;
